import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Template from 'templates/Default'
import {
  IconCogwheel,
  IconCross,
  IconEmCasa,
  IconExclamationMark,
  IconSearch,
  IconSpin,
  IconTrafficCone,
  IconWhatsApp,
} from 'icons'
import Result, { TProps as TResultProps } from 'components/Result'
import Anchor from 'components/Anchor'
import * as events from './events'
import styles from './Error.module.scss'

export type TProps = {
  type:
    | 'notfound'
    | 'error'
    | 'progress'
    | 'maintenance'
    | 'system-maintenance'
    | 'partners-maintenance'
  error?: Error
  statusCode?: number
  head?: {
    title?: string
    description?: string
    url?: string
  }
}

export const links = [
  {
    label: 'Página inicial',
    props: {
      href: '/',
      Icon: {
        Component: IconEmCasa,
        title: 'Ícone: EmCasa',
      },
    },
  },
  {
    label: 'Buscar imóveis',
    props: {
      href: '/imoveis',
      Icon: {
        Component: IconSearch,
        title: 'Ícone: Busca',
      },
    },
  },
]

export const resultMap = {
  notfound: {
    title: 'Página não encontrada',
    text: 'Não encontramos o que você está procurando. Aqui estão alguns links que podem ser úteis:',
    Icon: {
      Component: IconCross,
      title: 'Ícone: Não encontrado',
      color: 'grey900',
    },
    hideLinks: false,
    showButtons: false,
  },
  error: {
    title: 'Não conseguimos conectar',
    text: 'Esse conteúdo não está acessível no momento. Estamos trabalhando para corrigir o problema. Você pode tentar atualizar a página para ver se já normalizou ou entrar em contato pelo WhatsApp.',
    Icon: {
      Component: IconExclamationMark,
      title: 'Ícone: Erro',
      color: 'grey900',
    },
    hideLinks: true,
    showButtons: true,
  },
  progress: {
    title: 'Página em construção',
    text: 'Essa página está em construção e não pode ser usada no momento. Você pode tentar atualizar para ver se já está pronta ou ver outras páginas. Aqui estão alguns links que podem ser úteis:',
    Icon: {
      Component: IconTrafficCone,
      title: 'Ícone: Não encontrado',
      color: 'grey900',
    },
    hideLinks: false,
    showButtons: false,
  },
  maintenance: {
    title: 'Página em manutenção',
    text: 'Estamos fazendo manutenção para melhorar a sua experiência. Você pode tentar atualizar para ver se já está pronta ou ver outras páginas. Aqui estão alguns links que podem ser úteis:',
    Icon: {
      Component: IconCogwheel,
      title: 'Ícone: Não encontrado',
      color: 'grey900',
    },
    hideLinks: false,
    showButtons: false,
  },
  'system-maintenance': {
    title: 'Site em manutenção',
    text: 'Estamos fazendo manutenção para melhorar a sua experiência. Aguarde mais um pouco e atualize para ver se já terminamos ou fale conosco via WhatsApp.',
    Icon: {
      Component: IconCogwheel,
      title: 'Ícone: Desligado',
      color: 'grey900',
    },
    hideLinks: true,
    showButtons: true,
  },
  'partners-maintenance': {
    title: 'Programa de parceria em manutenção',
    text: 'Para atender melhor nossos parceiros, estamos reformulando nosso programa de parceria. Durante esse período, não será possível fazer indicações de imóveis. Todas as indicações feitas anteriormente continuam válidas e seguem as regras e valores estipulados no momento da indicação.',
    Icon: {
      Component: IconCogwheel,
      title: 'Ícone: Desligado',
      color: 'grey900',
    },
    hideLinks: false,
    showButtons: false,
  },
}

const ErrorPage = ({ type, error, statusCode, head }: TProps) => {
  const router = useRouter()

  const buttons = [
    {
      label: 'Fale conosco',
      props: {
        theme: 'green',
        element: 'a',
        target: '_blank',
        href: 'https://api.whatsapp.com/send?phone=551131976638&text=Quero%20falar%20com%20a%20EmCasa%20para%20tirar%20d%C3%BAvidas',
        onClick: () => events.contactButton(type),
        Icon: {
          Component: IconWhatsApp,
          title: 'Ícone: WhatsApp',
          color: 'white',
        },
      },
    },
    {
      label: 'Atualizar página',
      props: {
        Icon: {
          Component: IconSpin,
          title: 'Ícone: Atualizar',
          color: 'grey900',
        },
        onClick: () => {
          events.reloadButton(type)
          void router.reload()
        },
      },
    },
  ] as TResultProps['buttons']

  useEffect(() => events.pageLoad({ type, error, statusCode }), [])

  return (
    <Template
      head={{
        title: head?.title || resultMap[type].title,
        description: head?.description || resultMap[type].title,
        url: head?.url || router.asPath,
      }}
    >
      <Result
        fullOnAPage
        width="big"
        Icon={resultMap[type].Icon}
        title={resultMap[type].title}
        text={resultMap[type].text}
        buttons={resultMap[type]?.showButtons ? buttons : undefined}
      >
        {!resultMap[type].hideLinks ? (
          <div className={styles.ecTemplateErrorLinks}>
            {links.map((link, index) => (
              <Anchor
                key={index}
                {...link.props}
                onClick={() =>
                  events.pageLinks({ label: link.label, url: link.props.href })
                }
              >
                {link.label}
              </Anchor>
            ))}
          </div>
        ) : null}
      </Result>
    </Template>
  )
}

export default ErrorPage
