import { FC, PropsWithChildren, ReactNode } from 'react'
import { TIconComponent } from 'icons/types'
import Typography, { TVariant } from 'components/Typography'
import Button, { TProps as TPropsButton } from 'components/Button'
import classNames from 'classnames'
import styles from './Result.module.scss'

type TSize = 'small' | 'medium' | 'big'

type TSizeMap = Record<
  string,
  {
    containerClassName: string | null
    iconWrapperClassName: string | null
    assetWrapperClassName: string | null
    iconSize: number
    title: TVariant
    text: TVariant
  }
>

export type TProps = {
  title: string
  text?: ReactNode
  Icon?: Omit<TIconComponent, 'width' | 'height'>
  Image?: ReactNode
  buttons?: Array<{
    label: string
    props?: TPropsButton
  }>
  width?: TSize
  size?: TSize
  status?: 'info' | 'success' | 'error'
  fullOnAPage?: boolean
}

const Result: FC<PropsWithChildren<TProps>> = ({
  children,
  title,
  text,
  Icon,
  Image,
  buttons,
  width = 'medium',
  size = 'medium',
  status = 'info',
  fullOnAPage,
}) => {
  const widthMap = {
    small: {
      containerClassName: styles.ecResultContainerWidthSmall,
      buttonsClassName: null,
    },
    medium: {
      containerClassName: null,
      buttonsClassName: null,
    },
    big: {
      containerClassName: styles.ecResultContainerWidthBig,
      buttonsClassName: styles.ecResultButtonsBig,
    },
  }

  const sizeMap: TSizeMap = {
    small: {
      containerClassName: styles.ecResultContainerSmall,
      iconWrapperClassName: styles.ecResultIconWrapperSmall,
      iconSize: 24,
      assetWrapperClassName: styles.ecResultImageWrapperSmall,
      title: 'TitleSmall',
      text: 'ParagraphSmall',
    },
    medium: {
      containerClassName: null,
      iconWrapperClassName: null,
      iconSize: 40,
      assetWrapperClassName: null,
      title: 'Title',
      text: 'Paragraph',
    },
    big: {
      containerClassName: styles.ecResultContainerBig,
      iconWrapperClassName: styles.ecResultIconWrapperBig,
      iconSize: 52,
      assetWrapperClassName: styles.ecResultImageWrapperBig,
      title: 'TitleBig',
      text: 'Paragraph',
    },
  }

  const statusMap = {
    info: {
      iconWrapperClassName: null,
    },
    success: {
      iconWrapperClassName: styles.ecResultIconWrapperSuccess,
    },
    error: {
      iconWrapperClassName: styles.ecResultIconWrapperError,
    },
  }

  const className = classNames(styles.ecResult, {
    [styles.ecResultFullOnAPage]: fullOnAPage,
  })

  const containerClassName = classNames(
    styles.ecResultContainer,
    widthMap[width]?.containerClassName,
    sizeMap[size]?.containerClassName,
  )

  const iconWrapperClassName = classNames(
    styles.ecResultIconWrapper,
    statusMap[status]?.iconWrapperClassName,
    sizeMap[size]?.iconWrapperClassName,
  )

  const assetWrapperClassName = classNames(
    styles.ecResultImageWrapper,
    sizeMap[size]?.assetWrapperClassName,
  )

  const buttonsClassName = classNames(
    styles.ecResultButtons,
    widthMap[width]?.buttonsClassName,
  )

  return (
    <div className={className}>
      <div className={containerClassName}>
        {Icon && (
          <div className={iconWrapperClassName}>
            <Icon.Component
              color={Icon?.color}
              title={Icon?.title}
              width={sizeMap[size].iconSize}
              height={sizeMap[size].iconSize}
            />
          </div>
        )}
        {Image && <div className={assetWrapperClassName}>{Image}</div>}
        {(title || text) && (
          <div className={styles.ecResultCopies}>
            {title && (
              <Typography variant={sizeMap[size].title} bold>
                {title}
              </Typography>
            )}
            {text && (
              <Typography variant={sizeMap[size].text}>{text}</Typography>
            )}
          </div>
        )}
        {buttons && (
          <div className={buttonsClassName}>
            {buttons?.map(({ props = {}, label }) => (
              <Button
                key={label}
                {...props}
                className={classNames(styles.ecResultButton, props.className)}
              >
                {label}
              </Button>
            ))}
          </div>
        )}
        {children && <div className={styles.ecResultChildren}>{children}</div>}
      </div>
    </div>
  )
}

export default Result
