import logger from 'lib/logger'

export const pageLoad = ({
  type,
  error,
  statusCode,
}: {
  type: string
  error?: Error
  statusCode?: number
}) => logger.action('page-error', { type, error, statusCode })

export const pageLinks = ({ label, url }: { label: string; url: string }) =>
  logger.action('page-error-link', { label, url })

export const contactButton = (type: string) =>
  logger.action('page-error-contact-button', { type })

export const reloadButton = (type: string) =>
  logger.action('page-error-reload-button', { type })
